import React, { useEffect, useState } from 'react';
import Loader from '../../components/loader/Loader';
import './index.scss';
import Ab from '../../assets/images/aboutus.png';
import Ab1 from '../../assets/images/aboutus1.png';
import Ab2 from '../../assets/images/aboutus2.png';
import Ab3 from '../../assets/images/aboutus3.png';
import Ab4 from '../../assets/images/aboutus4.png';

console.log(Ab);
console.log(Ab1);
console.log(Ab2);
console.log(Ab3);
console.log(Ab4);

const Aboutus = () => {
  const screenWidth = window.innerWidth;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const loadImages = async () => {
      const images = document.querySelectorAll('img');
      const imagePromises = Array.from(images).map(img => {
        if (img.complete) {
          return Promise.resolve();
        }
        return new Promise(resolve => {
          img.addEventListener('load', resolve, { once: true });
          img.addEventListener('error', resolve, { once: true });
        });
      });

      await Promise.all(imagePromises);
      
      if (isMounted) {
        setIsLoading(false);
      }
    };

    loadImages();

    return () => {
      isMounted = false;
      setIsLoading(true); // Reset loading state on unmount
    };
  }, []);



  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section
            className='aboutus-container animate-toastFade px-4 mt-12 mb-20 pt-10 pb-24  bg-theme'>
            <div>
              <div className="box">
                <h2
                  className='letter text-8x1 md:text-8xl text-white'>IDME</h2>
              </div>
              <h1
                className='text-center text-4x1 md:text-4xl text-black font-bold m-6'>
                Integrated Digital Manufacturing Ecosystem</h1>
              <p
                className='para md:text-lg text-base mt-10 pr-8 pl-8 md:w-3/5 md:mx-auto'>The Integrated Digital Manufacturing Ecosystem (IDME) represents a transformative approach to manufacturing, combining cutting-edge technologies like blockchain, artificial intelligence (AI), and automation. It creates a fully interconnected, decentralized platform that redefines how products are designed, manufactured, and distributed.
                IDME acts as a "digital twin" of the entire manufacturing value chain, enabling seamless collaboration, real-time insights, and unparalleled efficiency. It is designed to empower designers, manufacturers, and businesses by fostering innovation, transparency, and scalability.</p>
            </div>
            <div>
              <div >
              <img src={Ab} alt="Ab" className='img mt-20' />
              </div>
              <h1
                className='text-center text-4x1 md:text-4xl text-black font-bold m-6'>
                Digital Asset Framework</h1>
              <p
                className='para md:text-lg text-base mt-10 pr-8 pl-8 md:w-3/5 md:mx-auto'>The Digital Asset Framework (DAF) is a core component of 3dHoudini's Integrated Digital Manufacturing Ecosystem (IDME). It is designed to securely convert physical and digital product designs into traceable, secure, and functional digital assets. This framework underpins the secure exchange of intellectual property (IP), lifecycle management of products, and ensures seamless integration between design and production in a decentralized environment.</p>
            </div>
            <div>
              <div >
                <img src={Ab1} alt="Ab1" className='img mt-20' />
              </div>
              <h1
                className='text-center text-4x1 md:text-4xl text-black font-bold m-6'>
                Automated Value Chain Settlement</h1>
              <p
                className='para md:text-lg text-base mt-10 pr-8 pl-8 md:w-3/5 md:mx-auto'>Automated Value Chain Settlement (AVCS) is a transformative system designed to simplify and optimize the financial transactions within manufacturing and supply chains. By leveraging smart contracts and distributed ledger technology, AVCS ensures seamless, transparent, and automated payments for all participants in the value chain.</p>
            </div>
            <div>
              <div >
                <img src={Ab2} alt="Ab2" className='img mt-20' />
              </div>
              <h1
                className='text-center text-4x1 md:text-4xl text-black font-bold m-6'>
                Open Manufacturing Network</h1>
              <p
                className='para md:text-lg text-base mt-10 pr-8 pl-8 md:w-3/5 md:mx-auto'>The Open Manufacturing Network (OMN) is a decentralized and scalable infrastructure that connects designers, manufacturers, and suppliers through a global network of Build Centers. OMN enables on-demand, localized production, driving efficiency, sustainability, and innovation in the manufacturing industry. By eliminating the need for centralized factories, OMN empowers manufacturers worldwide to collaborate and fulfill production orders seamlessly.</p>
            </div>
            <div>
              <div >
                <img src={Ab3} alt="Ab3" className='img mt-20' />
              </div>
              <div >
                <img src={Ab4} alt="Ab4" className='img1 w-[35%] md:w-[240px] mt-6 ' />
              </div>
              <h1
                className='text-center text-4x1 md:text-4xl text-black font-bold m-6'></h1>
              <p
                className='para md:text-lg text-base mt-10 pr-8 pl-8 md:w-3/5 md:mx-auto'>MakeGoods is the commercial hub of the 3dHoudini ecosystem where creators, manufacturers, and consumers converge to design, produce, and distribute innovative products. It integrates 3dHoudini’s cutting-edge technologies—including the Digital Asset Framework (DAF), Automated Value Chain Settlement (AVCS), and the Open Manufacturing Network (OMN)—into a seamless platform for decentralized, on-demand manufacturing.
                <br></br>MakeGoods enables creators to securely tokenize their designs, manufacturers to access and produce products locally, and consumers to purchase authentic, traceable goods. This decentralized marketplace simplifies supply chains, enhances transparency, and accelerates product development.</p>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Aboutus;
