import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from './Auth';

const Body = () => {
  const [isScreenShow, setIsScreenShow] = useState(true);
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (authData) {
      navigate('/studio/view-orders');
    }
  }, [navigate]);

  return (
    <div
      ref={scrollRef}
      className='flex md:h-[calc(100vh-65px)] h-[calc(100vh-41px)] overflow-y-auto w-full my-0 flex-col bg-bg-primary items-center gap-12'
    >
      {isScreenShow ? <Auth scrollRef={scrollRef} /> : null}
    </div>
  );
};

export default Body;
