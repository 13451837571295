import { IoClose } from 'react-icons/io5';
import { formatDate } from '../../../helpers';
import React from 'react';
import { adminIcon } from '../../../assets/images';
import { LuUserCircle2 } from 'react-icons/lu';
import { MdOutlineFileDownload } from 'react-icons/md';

const ActivityModal = ({ selectedActivityOrder, onClose }) => {
  const handleModalDownload = (url) => {
    if (url === null) {
      return;
    }
    const a = document.createElement('a');
    a.href = url;
    a.click();
  };

  const styles = {
    sectionTitle: 'font-semibold text-tx-primary text-lg mt-4 mb-1',
    activityRow:
      'flex py-4 border-b border-solid border-black/10 w-full gap-4 last:border-b-0',
    activityText: 'text-tx-secondary text-sm font-normal',
    closeSpan:
      'absolute text-tx-secondary right-2 top-3 transition-all hover:bg-black/10 rounded-md cursor-pointer',
    download:
      ' font-semibold transition-all hover:text-btn-primary cursor-pointer me-2 flex items-center gap-1'
  };

  return (
    <>
      {selectedActivityOrder && (
        <div className='fixed inset-0 flex items-center justify-center z-50 bg-black/[0.5]'>
          <div className='bg-white p-8 max-w-xl w-full flex flex-col items-start rounded-md shadow-md animate-zoomIn relative'>
            <span onClick={onClose} className={styles.closeSpan}>
              <IoClose size={22} />
            </span>
            <h2 className={styles.sectionTitle}>Activity</h2>
            <div className='w-full max-h-[70vh] overflow-auto'>
              {[...selectedActivityOrder.activity]
                .reverse()
                .filter((activity) => activity.author)
                .map((activity, i) => (
                  <div className={styles.activityRow} key={i}>
                    <div className='w-11 flex items-start justify-center text-tx-secondary'>
                      {activity.author === 'User' ? (
                        <LuUserCircle2 size={36} />
                      ) : (
                        <img src={adminIcon} height={36} width={36} alt='' />
                      )}
                    </div>
                    <div className='flex-1'>
                      <p className='text-tx-secondary font-bold text-lg flex w-full justify-between items-center'>
                        {activity.author === 'User'
                          ? selectedActivityOrder.shipping.name
                          : '3dHoudini'}
                        <span className='font-normal text-xs'>
                          {formatDate(activity.timestamp)}
                        </span>
                      </p>
                      {i === selectedActivityOrder.activity.length - 1 ? (
                        <>
                          <p className={styles.activityText}>
                            {activity.remark?.length
                              ? activity.remark
                              : 'Uploaded model file'}
                          </p>
                        </>
                      ) : (
                        <p className={styles.activityText}>
                          {selectedActivityOrder.payment_status !==
                            'PROOF UNAPPROVED' &&
                          selectedActivityOrder.payment_status !==
                            'PROOF PENDING' &&
                          i === 0
                            ? 'Approved proof'
                            : activity.remark}
                        </p>
                      )}
                      {activity.attachmentUrl && activity.attachmentUrl.length ? (
                        activity.author === 'Admin' ? (
                          <div className='flex items-center justify-start flex-wrap gap-2 mt-1'>
                            {activity.attachmentUrl.map((url, index) => (
                              <p
                                onClick={() => handleModalDownload(url)}
                                className={
                                  styles.activityText + styles.download
                                }
                              >
                                Proof {index + 1} <MdOutlineFileDownload />
                              </p>
                            ))}
                          </div>
                        ) : (
                          <div className='flex items-center justify-start flex-wrap gap-2 mt-1'>
                            {activity.attachmentUrl.map((url, index) => (
                              <p
                                onClick={() => handleModalDownload(url)}
                                className={
                                  styles.activityText + styles.download
                                }
                              >
                                Asset {index + 1} <MdOutlineFileDownload />
                              </p>
                            ))}
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActivityModal;
