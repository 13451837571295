import { Button, Label } from '../../../../components/ui';
import React, { useEffect, useRef, useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import {
  approveOrderByUser,
  createPaymentSession,
  getOrderStatus
} from '../../../../services';
import EditModal from './EditModal';
import {
  RadioGroup,
  RadioGroupItem
} from '../../../../components/ui/radio-group';
import { toast } from '../../../../components/ui/use-toast';
import { RiLoader3Line } from 'react-icons/ri';
import config from '../../../../config.json';
import { formatDate, getOrderId } from '../../../../helpers';
import LoadingBar from 'react-top-loading-bar';
import { useLocation, useNavigate } from 'react-router-dom';
import AkcbLoader from '../../../../components/loader';
import { adminIcon } from '../../../../assets/images';
import { LuUserCircle2 } from 'react-icons/lu';
import { studioToast } from '../../../../components/toast';
import ImagesModal from './ImagesModal';

const Checkout = () => {
  const [selectedOrder, setSelectedOrder] = useState();
  const [selectedPayment, setSelectedPayment] = useState('Credit card');
  const [comment, setComment] = useState('');
  const [editFileData, setEditFileData] = useState([null]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [inProgressBtn, setInProgressBtn] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const loaderRef = useRef(null);
  const paymentOptions = ['Credit card', 'Crypto'];
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const orderId = getOrderId(pathname);

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      getSelectedOrder();
    }
  }, [orderId]);

  const getSelectedOrder = async () => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      setLoading(false);
      return;
    }

    try {
      const response = await getOrderStatus(authData._id);
      if (response.status === 200 && response.data !== null) {
        const order = response.data.find((order) => order._id === orderId);
        if (order) {
          setSelectedOrder(order);
          setLoading(false);
        } else {
          navigate('/studio/view-orders');
        }
      } else {
        navigate('/studio/view-orders');
      }
    } catch (error) {
      console.log('Error:--', error);
      navigate('/studio/create-order');
    }
  };

  const handleApproveOrder = async (isCheckout) => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      return;
    }

    loaderRef.current.continuousStart();
    setIsInProgress(true);
    setInProgressBtn(isCheckout ? 'approve' : 'reject');
    setIsModalOpen(false);

    const formData = new FormData();

    formData.append('account', authData._id);
    formData.append('order_id', selectedOrder?._id);
    formData.append('remark', comment);

    const filteredFileData = editFileData.filter((item) => item !== null);
    if (filteredFileData) {
      filteredFileData.forEach((file) => {
        formData.append('attachment', file); // 'files' is the key to which all files will be appended
      });
    }
    formData.append('checkout', String(isCheckout));
    formData.append(
      'timestamp',
      String(
        selectedOrder.activity[selectedOrder.activity.length - 1].timestamp
      )
    );

    try {
      const res = await approveOrderByUser(formData);
      if (res.status === 200) {
        // toast({
        //   description: isCheckout
        //     ? "Thanks for approving the proof!"
        //     : "Thanks for the feedback. We'll get back to you shortly."
        // });
        //
        studioToast(
          isCheckout
            ? 'Thanks for approving the proof!'
            : "Thanks for the feedback. We'll get back to you shortly."
        );

        await getSelectedOrder();
      }
    } catch (error) {
      console.log('Error:--', error);
    }
    setComment('');
    setIsInProgress(false);
    loaderRef?.current?.complete();
    setInProgressBtn('');
  };

  const handleCheckout = async () => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (!authData) {
      return;
    }

    loaderRef.current.continuousStart();
    setIsInProgress(true);
    try {
      const res = await createPaymentSession(
        authData._id,
        selectedOrder?._id,
        selectedPayment === 'Credit card' ? 'CARD' : 'CRYPTO'
      );

      if (res.status === 200) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      console.log('Error:--', error);
      // toast({
      //   description: "Something went wrong."
      // });
      studioToast('Something went wrong.');
    }
    loaderRef?.current?.complete();
  };

  const handleCompleteCheckout = () => {
    loaderRef.current.continuousStart();
    setIsInProgress(true);
    window.location.href = selectedOrder.payment_url;
  };

  const onClose = () => {
    setComment('');
    setIsModalOpen(false);
  };

  const styles = {
    heading: 'sm:text-5xl text-3xl font-extralight text-tx-primary',
    imageDiv:
      'w-20 h-20 cursor-pointer rounded-lg bg-transparent flex items-center justify-center overflow-hidden',
    sectionTitle: 'font-light text-black/80 text-3xl mt-10 mb-2',
    price:
      'w-[48%] rounded-lg bg-white/20 h-10 flex items-center justify-center text-white font-bold text-xl',
    orderDataRow: 'text-tx-secondary text-sm font-medium',
    orderDataSpan: 'font-semibold ',
    activityRow:
      'flex py-4 border-b border-solid border-slate-600/[0.15] w-full gap-4',
    activityText: 'text-tx-secondary text-sm mt-1'
  };

  const imageURLs = !selectedOrder
    ? []
    : selectedOrder.activity.length > 1
      ? selectedOrder.activity[selectedOrder.activity.length - 1].author ===
        'Admin'
        ? selectedOrder.activity[selectedOrder.activity.length - 1]
            .attachmentUrl
        : selectedOrder.activity[selectedOrder.activity.length - 2]
            .attachmentUrl
      : [];

  const OrderDetailsSection = () => {
    return (
      <>
        <div className='w-full flex items-start justify-between my-4'>
          <div className='flex justify-start'>
            <h1 className={styles.heading}>ORDER #{selectedOrder.id}</h1>
          </div>
          <span
            onClick={() => navigate('/studio/view-orders')}
            className='rounded-md sm:p-2 p-1.5 bg-white/20 cursor-pointer transition-all hover:bg-white/25 text-tx-primary'
          >
            <IoMdArrowRoundBack />
          </span>
        </div>
        <div className='mb-12'>
          <p className='w-fit text-font-theme text-sm sm:font-bold border border-solid border-white/50 bg-white/80 py-1 px-2 rounded-md flex justify-center'>
            {selectedOrder.status}
          </p>
        </div>
      </>
    );
  };

  const ConditionalButtons = () => {
    return (
      <>
        <div className='border border-solid border-white/20 bg-white/10 p-4 my-6 rounded-lg'>
          {selectedOrder.status === 'PROOF UNAPPROVED' &&
          imageURLs.length === 0 ? (
            <></>
          ) : (
            <p className='text-md font-normal text-black'>
              Price :{' '}
              <span className={styles.orderDataSpan}>
                ${selectedOrder.cost}
              </span>
            </p>
          )}

          {selectedOrder.status === 'PAYMENT COMPLETE' ? (
            <p className='text-md font-normal text-black mt-2'>
              We have received your payment. Please visit this page to track
              your order status.
            </p>
          ) : selectedOrder.status === 'PAYMENT PENDING' ? (
            <>
              <p className='text-md font-normal text-black mt-2'>
                Payment method :{' '}
                <span className={styles.orderDataSpan}>
                  {selectedOrder?.payment_method === 'COINBASE'
                    ? 'CRYPTO'
                    : selectedOrder?.payment_method === 'STRIPE'
                      ? 'CREDIT CARD'
                      : '--'}
                </span>
              </p>
              <Button
                disabled={isInProgress}
                onClick={handleCompleteCheckout}
                className='mt-6 mb-2 disabled:opacity-100'
              >
                {isInProgress ? (
                  <RiLoader3Line className='animate-spin' size={20} />
                ) : (
                  'Complete checkout'
                )}
              </Button>
            </>
          ) : selectedOrder.status === 'PROOF APPROVED' ? (
            <>
              <p className='text-tx-secondary text-sm mb-1.5 font-medium mt-6'>
                Pay via
              </p>
              <RadioGroup
                defaultValue={paymentOptions[0]}
                onValueChange={(value) => setSelectedPayment(value)}
                value={selectedPayment}
              >
                {paymentOptions.map((size, i) => (
                  <div key={i} className='flex items-center space-x-2'>
                    <RadioGroupItem value={size} id={size} />
                    <Label htmlFor={size}>{size}</Label>
                  </div>
                ))}
              </RadioGroup>
              <Button
                onClick={handleCheckout}
                disabled={isInProgress}
                className='mt-6 mb-2 disabled:opacity-100'
              >
                {isInProgress ? (
                  <RiLoader3Line className='animate-spin' size={20} />
                ) : (
                  'Checkout'
                )}
              </Button>
            </>
          ) : selectedOrder.status === 'PROOF UNAPPROVED' &&
            imageURLs.length ? (
            <>
              <div className='flex justify-start gap-3 items-center mt-4'>
                <Button
                  onClick={() => handleApproveOrder(true)}
                  disabled={isInProgress}
                  className='disabled:opacity-100'
                >
                  {isInProgress && inProgressBtn === 'approve' ? (
                    <RiLoader3Line className='animate-spin' size={20} />
                  ) : (
                    'Approve'
                  )}
                </Button>
                <Button
                  disabled={isInProgress}
                  className='disabled:opacity-100'
                  variant='secondary'
                  onClick={() => setIsModalOpen(true)}
                >
                  {isInProgress && inProgressBtn === 'reject' ? (
                    <RiLoader3Line className='animate-spin' size={20} />
                  ) : (
                    'Reject'
                  )}
                </Button>
              </div>
            </>
          ) : selectedOrder.status === 'PROOF UNAPPROVED' &&
            imageURLs.length === 0 ? (
            <>
              <p className='text-tx-secondary text-sm mb-1.5 font-medium'>
                {
                  selectedOrder.activity[selectedOrder.activity.length - 1]
                    .remark
                }
              </p>
              <Button
                disabled={isInProgress}
                className='disabled:opacity-100 mt-4'
                onClick={() => setIsModalOpen(true)}
              >
                {isInProgress ? (
                  <RiLoader3Line className='animate-spin' size={20} />
                ) : (
                  'Respond'
                )}
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  const ProofSection = () => {
    return (
      <>
        <h2 className={styles.sectionTitle}>Proof</h2>
        <div className='w-full flex justify-start gap-6 flex-wrap items-start mt-6'>
          {imageURLs.map((url, index) => (
            <div
              className={styles.imageDiv}
              onClick={() => setSelectedIndex(index)}
            >
              <img src={url} className='h-full w-full object-contain' alt='' />
            </div>
          ))}
        </div>
      </>
    );
  };

  const ActivitySection = () => {
    return (
      <>
        <h2 className={styles.sectionTitle}>Activity</h2>
        {[...selectedOrder.activity].reverse().filter((act) => act.author).map((activity, i) => (
          <div key={i} className={styles.activityRow}>
            <div className='w-11 flex items-start justify-center text-tx-secondary'>
              {activity.author === 'User' ? (
                <LuUserCircle2 size={36} />
              ) : (
                // <img
                //   src={`${config.pfpUri}/${selectedOrder.tokenId[0]}.png`}
                //   className='h-9 w-9 border border-solid border-black/20 rounded-[50px]'
                //   alt=''
                // />
                <img src={adminIcon} height={36} width={36} alt='' />
              )}
            </div>
            <div className='flex-1'>
              <p className='text-tx-secondary font-bold text-lg flex w-full justify-between items-center'>
                {activity.author === 'User' ? 'You' : '3dHoudini'}
                <span className='font-normal text-xs'>
                  {formatDate(activity.timestamp)}
                </span>
              </p>
              <p className={styles.activityText}>
                {i === selectedOrder.activity.length - 1 &&
                !activity.remark?.length
                  ? 'Order created'
                  : selectedOrder.status !== 'PROOF UNAPPROVED' &&
                      selectedOrder.status !== 'PROOF PENDING' &&
                      i === 0
                    ? 'Approved proof'
                    : activity.remark}
              </p>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <LoadingBar
        color={config.loaderBar}
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={200}
        ref={loaderRef}
      />
      {loading ? (
        <AkcbLoader />
      ) : (
        <div className='sm:w-[840px] w-full border-0 border-solid border-white/25 rounded-sm py-4 sm:px-20 mb-20 px-4'>
          <OrderDetailsSection />
          {selectedOrder.activity.length > 1 &&
          selectedOrder.status !== 'PROOF PENDING' ? (
            <ConditionalButtons />
          ) : null}

          {selectedOrder.status === 'PROOF UNAPPROVED' && imageURLs.length ? (
            <ProofSection />
          ) : null}

          <ActivitySection />
          <EditModal
            isModalOpen={isModalOpen}
            comment={comment}
            setComment={setComment}
            editFileData={editFileData}
            setEditFileData={setEditFileData}
            onClose={onClose}
            handleApproveOrder={handleApproveOrder}
          />
          <ImagesModal
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            imageURLs={imageURLs}
          />
        </div>
      )}
    </>
  );
};

export default Checkout;
