import React, { useEffect, useMemo, useRef, lazy } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom';
import { allRoutes } from './constants';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import LoadingBar from 'react-top-loading-bar';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import NotFoundPage from './pages/NotFoundPage';
import RouteWrapper from './components/RouteWrapper';

// Create a context for the loading bar
export const LoadingContext = React.createContext(null);

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const loadingRef = useRef(null);

  // Compute effective path, handling /studio redirect
  const effectivePath = useMemo(() => {
    if (location.pathname === '/studio' || location.pathname === '/studio/') {
      return '/studio/connect';
    }
    return location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // If we need to redirect, do it before rendering
  if (effectivePath !== location.pathname) {
    return <Navigate to={effectivePath} replace />;
  }

  return (
    <LoadingContext.Provider value={loadingRef}>
      <LoadingBar
        color='#FF5733'
        height={3}
        containerStyle={{ zIndex: 101 }}
        transitionTime={300}
        ref={loadingRef}
      />
      <Header />
      <Routes location={location} key={effectivePath}>
        {allRoutes.map(({ Component, path }, i) => (
          <Route 
            exact={true} 
            path={path} 
            key={i} 
            element={<RouteWrapper Component={Component} />} 
          />
        ))}
        <Route path='*' exact={true} element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </LoadingContext.Provider>
  );
}

export default App;
