import React, { useEffect } from 'react';

const RouteWrapper = ({ Component }) => {
  useEffect(() => {
    // Setup effect
    document.body.style.overflow = 'auto';
    
    return () => {
      // Cleanup effect
      document.body.style.overflow = 'auto';
      
      // Remove any lingering modals or overlays
      const modals = document.querySelectorAll('.modal-overlay, .ReactModal__Overlay');
      modals.forEach(modal => {
        modal.remove();
      });

      // Remove any lingering event listeners
      const cleanup = () => {
        // This helps ensure event listeners are cleaned up
        window.removeEventListener('mousemove', cleanup);
        window.removeEventListener('mouseup', cleanup);
        window.removeEventListener('keydown', cleanup);
      };
      cleanup();
    };
  }, []);

  return <Component />;
};

export default RouteWrapper;
