import React, { useEffect, useState } from 'react';
import Loader from '../../components/loader/Loader';
import './index.scss';
import Faqs from '../../assets/images/faqs.png';

console.log(Faqs);

const FAQs = () => {
    const screenWidth = window.innerWidth;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const loadImages = async () => {
            const images = document.querySelectorAll('img');
            const imagePromises = Array.from(images).map(img => {
                if (img.complete) {
                    return Promise.resolve();
                }
                return new Promise(resolve => {
                    img.addEventListener('load', resolve, { once: true });
                    img.addEventListener('error', resolve, { once: true });
                });
            });

            await Promise.all(imagePromises);
            
            if (isMounted) {
                setIsLoading(false);
            }
        };

        loadImages();

        return () => {
            isMounted = false;
            setIsLoading(true); // Reset loading state on unmount
        };
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <section className=' mb-20 pb-24  bg-theme'>
                        <div>
                            <img src={Faqs} alt="Faqs" className='w-4/5 md:w-3/5 mx-auto mt-12' />
                        </div>
                        {/* Platform section */}
                        <div>
                            <div className='px-4 flex justify-center text-4xl md:text-5xl text-black font-bold mt-10 pt-14 '>
                                <h1 className='mt-5 hidden md:block'>Q</h1>
                                <h1 className='Water mr-14 md:mr-20 ml-14 md:ml-20 text-center'>PLATFORM</h1>
                                <h1 className='mt-5 hidden md:block'>A</h1>
                            </div>
                            <div className="flex flex-col items-center max-w-[80%] md:max-w-[60%] mx-auto space-y-10 mt-6">
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is 3dHoudini, and how does it work?</h6>
                                    <p className='w-full md:w-1/2'>3dHoudini is a smart manufacturing platform that leverages blockchain technology to tokenize products and designs, enabling secure, transparent, and efficient manufacturing.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>How does 3dHoudini differ from traditional manufacturing models?</h6>
                                    <p className='w-full md:w-1/2'>3dHoudini transforms traditional manufacturing by decentralizing the process, empowering IP owners to build their own supply chains and establish direct relationships. Manufacturers gain opportunities to build strong reputations and compete more effectively in a dynamic, transparent ecosystem.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is the benefit of using a decentralized manufacturing network?</h6>
                                    <p className='w-full md:w-1/2'>A decentralized manufacturing network enables local production, reducing costs, lead times, and environmental impact. It enhances supply chain resilience, supports customization, fosters direct relationships between IP holders and manufacturers, and promotes sustainability through reduced transportation and waste</p>
                                </div>
                            </div>
                        </div>

                        {/* Technology section */}
                        <div>
                            <div className='px-4 flex justify-center text-4xl md:text-5xl text-black font-bold mt-10 pt-14 '>
                                <h1 className='mt-5 hidden md:block'>Q</h1>
                                <h1 className='Water mr-14 md:mr-20 ml-14 md:ml-20 text-center'>TECHNOLOGY</h1>
                                <h1 className='mt-5 hidden md:block'>A</h1>
                            </div>
                            <div className="flex flex-col items-center max-w-[80%] md:max-w-[60%] mx-auto space-y-10 mt-6">
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is Tokenization?</h6>
                                    <p className='w-full md:w-1/2'>Tokenization is the process of converting real-world assets, rights, or data into digital tokens. These tokens represent the value or attributes of the underlying asset and can be used, transferred, or tracked within a digital ecosystem.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is a blockchain?</h6>
                                    <p className='w-full md:w-1/2'>A blockchain is a decentralized, digital ledger that securely records transactions across a network of computers. It ensures transparency, immutability, and trust without relying on a central authority</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>Why Does 3dhoudini use blockchain?</h6>
                                    <p className='w-full md:w-1/2'>3dHoudini uses blockchain to ensure transparency, secure intellectual property, enable trust, and streamline transactions in decentralized manufacturing.</p>
                                </div>
                            </div>
                        </div>
                       
                        {/* Terms section */}
                        <div>
                            <div className='px-4 flex justify-center text-4xl md:text-5xl text-black font-bold mt-10 pt-14 '>
                                <h1 className='mt-5 hidden md:block'>Q</h1>
                                <h1 className='Water mr-14 md:mr-20 ml-14 md:ml-20 text-center'>TERMS</h1>
                                <h1 className='mt-5 hidden md:block'>A</h1>
                            </div>
                            <div className="flex flex-col items-center max-w-[80%] md:max-w-[60%] mx-auto space-y-10 mt-6">
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is a "Digital Product Passport"?</h6>
                                    <p className='w-full md:w-1/2'>A Digital Product Passport is a record that tracks a product's information, including origin, composition, and lifecycle, ensuring transparency, traceability, and compliance with regulations.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What does "tokenized IP" mean?</h6>
                                    <p className='w-full md:w-1/2'>Tokenized IP is intellectual property, product details, prosesses represented as a digital token, enabling secure ownership, traceability, and streamlined licensing or transactions</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is "decentralized manufacturing"?</h6>
                                    <p className='w-full md:w-1/2'>Decentralized manufacturing is a system where production is distributed across multiple locations, connecting designers, consumers, and manufacturers directly to create products locally, efficiently, and sustainably</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What are "parent and child" Tokens"?</h6>
                                    <p className='w-full md:w-1/2'>Parent and Child Tokens are a hierarchical structure in which the Parent Token represents the intellectual property (IP) ownership of a product, while the Child Token represents individual units of that product, enabling traceability and ownership management.</p>
                                </div>
                            </div>
                        </div>

                        {/* Sustainability section */}
                        <div>
                            <div className='px-4 flex justify-center text-4xl md:text-5xl text-black font-bold mt-10 pt-14 '>
                                <h1 className='mt-5 hidden md:block'>Q</h1>
                                <h1 className='Water mr-14 md:mr-20 text-4xl md:text-4xl ml-14 md:ml-20 text-center'>SUSTAINABILITY AND <br></br>SOCIAL IMPACT</h1>
                                <h1 className='mt-5 hidden md:block'>A</h1>
                            </div>
                            <div className="flex flex-col items-center max-w-[80%] md:max-w-[60%] mx-auto space-y-10 mt-6">
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>How does 3dHoudini contribute to sustainability?</h6>
                                    <p className='w-full md:w-1/2'>3dHoudini contributes to sustainability by decentralizing manufacturing, reducing waste, lowering transportation emissions, and enabling localized production. Tokenized designs ensure efficient resource use and transparency, promoting ethical sourcing and reducing environmental impact</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>Does the platform support local communities?</h6>
                                    <p className='w-full md:w-1/2'>Yes, 3dHoudini supports local communities by enabling c demand, localized manufacturing. This reduces reliance global supply chains, creates local job opportunities, and promotes sustainable production closer to where produc are needed.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>Can 3dHoudini help small manufacturers compete globally?</h6>
                                    <p className='w-full md:w-1/2'>Yes, 3dHoudini helps small manufacturers compete glob-by providing access to a decentralized network, reducing barriers to entry, and allowing them to produce products demand while connecting with designers and customers worldwide.</p>
                                </div>
                            </div>
                        </div>

                        {/* Invest section */}
                        <div>
                            <div className='px-4 flex justify-center text-4xl md:text-5xl text-black font-bold mt-10 pt-14 '>
                                <h1 className='mt-5 hidden md:block'>Q</h1>
                                <h1 className='Water mr-14 md:mr-20 ml-14 md:ml-20 text-center'>INVEST</h1>
                                <h1 className='mt-5 hidden md:block'>A</h1>
                            </div>
                            <div className="flex flex-col items-center max-w-[80%] md:max-w-[60%] mx-auto space-y-10 mt-6">
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>Why should I invest in 3dHoudini?</h6>
                                    <p className='w-full md:w-1/2'>Investing in 3dHoudini offers the opportunity to be part of a disruptive, blockchain-powered platform revolutionizing decentralized manufacturing. With a scalable, global network, tokenized IP, and a focus on sustainability, it addresses key market needs, provides transparency, and empowers businesses to reduce costs while expanding their reach.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is the market potential of decentralized manufacturing?</h6>
                                    <p className='w-full md:w-1/2'>The market potential of 3dHoudini is vast, estimates show the tokenized asset market could reach a staggering $10 trillion within this decade. The global smart manufacturing market was valued at USD 254.24 billion in 2022 and is anticipated to grow at a CAGR of 14.9% from 2023 to 2030.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>How scalable is the 3dHoudini platform?</h6>
                                    <p className='w-full md:w-1/2'>The 3dHoudini platform is highly scalable, designed to grow globally by connecting decentralized manufacturing hubs. Its blockchain-based infrastructure allows for efficient expansion, enabling anyone to contribut to the growth of the network</p>
                                </div>
                            </div>
                        </div>

                        {/* Future section */}
                        <div>
                            <div className='px-4 flex justify-center text-4xl md:text-5xl text-black font-bold mt-10 pt-14 '>
                                <h1 className='mt-5 hidden md:block'>Q</h1>
                                <h1 className='Water mr-14 md:mr-20 ml-14 md:ml-20 text-center'>FUTURE</h1>
                                <h1 className='mt-5 hidden md:block'>A</h1>
                            </div>
                            <div className="flex flex-col items-center max-w-[80%] md:max-w-[60%] mx-auto space-y-10 mt-6">
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>What is the long-term vision for 3dHoudini?</h6>
                                    <p className='w-full md:w-1/2'>The long-term vision for 3dHoudini is to transform the manufacturing landscape by establishing a global, decentralized platform where designers, manufacturers, and consumers can effortlessly connect. The goal is to make 3dHoudini the go-to solution for all things manufacturing, offering scalability, efficiency, and sustainability.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>How does the platform plan to expand globally?</h6>
                                    <p className='w-full md:w-1/2'>3dHoudini plans to expand globally by utilizing its decentralized network, where anyone can contribute their tools and expertise. This organic growth model allows the network to expand where it's needed, making the system adaptable and scalable in diverse regions.</p>
                                </div>
                                <div className="flex flex-col md:flex-row w-full justify-center gap-4">
                                    <h6 className='w-full md:w-1/2 text-start md:text-end font-normal text-2xl mb-2 md:mb-0'>How can individuals and businesses get involved in the platform?</h6>
                                    <p className='w-full md:w-1/2'>Individuals and businesses can get involved in 3dHoudini by joining the decentralized network as designers, manufacturers, or consumers. They can contribute by sharing designs, utilizing manufacturing capabilities, or accessing products, with opportunities for collaboration and growth within the platform.</p>
                                </div>
                            </div>
                        </div>

                    </section>
                </>
            )}
        </>
    );
};

export default FAQs;
