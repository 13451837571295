import React, { useEffect, useState } from 'react';
import './index.scss';
import {
  printToken,
  smallPrintToken,
  specialEdition,
  homeText3dh
} from '../../assets/images';
import sequenceVideo from '../../assets/videos/homePageVideo.mp4';
import Loader from '../../components/loader/Loader';
import { Link, redirect } from 'react-router-dom';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    const videos = document.querySelectorAll('video');
    const totalResources = images.length + videos.length;

    let loadedResources = 0;

    const resourceLoaded = () => {
      loadedResources++;
      if (loadedResources === totalResources) {
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        resourceLoaded();
      } else {
        image.addEventListener('load', resourceLoaded);
      }
    });

    videos.forEach((video) => {
      if (video.readyState >= 3) {
        resourceLoaded();
      } else {
        video.addEventListener('canplaythrough', resourceLoaded);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', resourceLoaded);
      });
      videos.forEach((video) => {
        video.removeEventListener('canplaythrough', resourceLoaded);
      });
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='w-full animate-toastFade'>
          <div className='h-[calc(100svh-40px)]  bg-transparent relative w-full flex flex-col justify-center items-center gap-2'>
            <video
              autoPlay
              src={sequenceVideo}
              playsInline
              muted
              controlsList='nodownload'
              preload='metadata'
              loop
              className='h-[calc(100svh-40px)] md:w-full absolute top-0 left-0 -z-10 object-cover'
            />
            <img
              className='text-3dh-img w-[80%] h-auto md:w-[768px]'
              src={homeText3dh}
              alt='3dhoudini'
            />
            <p className='text-lg flex  md:text-3xl text-zinc-300 mb-32'>
              Tokenize Creativity, Manufacture the Future{' '}
            </p>
          </div>
          <div
            className='w-full h-screen min-h-[640px] md:min-h-[700px]
        max-h-[730px] md:max-h-fit flex flex-col 
        items-center gap-6 bg-theme border-b border-solid border-white/20'
          >
            <div className=' mt-20 md:ml-72 md:mr-72 mr-10 ml-10 justify-center space-y-5 md:space-y-10'>
              <h2 className='font-semibold md:text-7xl text-xl'>What we do?</h2>
              <p className='text-sm md:text-lg leading-snug '>
                3dHoudini tokenizes products. By doing this, we enable the
                product to be sold digitally, as a design. The physical product
                can then be manufactured locally, wherever there’s a demand for
                it and access to manufacturing.
              </p>
              <p className='text-sm md:text-lg leading-snug '>
                Our tokens function as a digital vehicle to distribute a
                product, authenticate its provenance, verify its quality,
                protect its IP, and facilitate automated payments to all
                stakeholders in the ecosystem.
              </p>
              <p className='text-sm md:text-lg leading-snug '>
                This is truly decentralized manufacturing. We make bringing a
                product to market faster and more affordable and provide end
                consumers with greater customization, higher quality, and better
                prices while increasing demand pipeline and opportunity for
                manufacturers.
              </p>
              <div className='flex justify-center'>
                <Link
                  to='/aboutus'
                  className='h-9 flex items-center justify-center font-bold text-sm px-4 rounded-lg transition-all disabled:pointer-events-none disabled:opacity-70 bg-btn-primary hover:bg-btn-primary/80 border-none text-white'
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
